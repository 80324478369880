import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  useTheme,
  Container,
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
} from "@material-ui/core";
// import MenuIcon from "@material-ui/icons/Menu";
// import MainDrawer from "./MainDrawer";
// import Works from "./Works/Works";
// import Footer from "./Footer";
// import Test from "./Test/Test";

import ContactFormModal from "./ContactForm/ContactFormModal";
import { staticValue } from "./../staticValue";
import Top from "./Top/Top";
import Notfound from "./Notfound/Notfound";

export default function App() {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="app">
      <Switch>
        {/* <Route exact path="/">
          <Container maxWidth="lg">
            <Box
              py={8}
              display="flex"
              minHeight="100vh"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Typography variant="h1" component="h2" gutterBottom>
                I'm not ready yet.
              </Typography>
            </Box>
          </Container>
        </Route> */}

        <Route exact path={`/${staticValue.links.home}`}>
          <Top
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
          />
        </Route>

        {/* <Route exact path={`/${staticValue.links.works}`}>
          <AppBar color="transparent" style={{ boxShadow: "none" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Works />
          <Footer />
        </Route> */}
        {/* 
        <Route exact path={`/${staticValue.links.test}`}>
          <AppBar color="transparent" style={{ boxShadow: "none" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Test
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
          />
          <Footer />
        </Route> */}
        <Route component={Notfound} />
      </Switch>

      {/* <MainDrawer
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />*/}
      <ContactFormModal
        open={openModal}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
      />
    </div>
  );
}
