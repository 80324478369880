import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  Button,
  Paper,
  Container,
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import InputLabel from "@material-ui/core/InputLabel";
import { db } from "./../../services/firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));
export default function ContactForm(props) {
  const [formState, setFormState] = React.useState({
    formStateName: "",
    formStateEmail: "",
    formStateEmailContent: "",
  });
  const classes = useStyles();

  const handleChange = (type, e) => {
    switch (type) {
      case "formStateName":
        setFormState({ ...formState, formStateName: e.target.value });
        break;
      case "formStateEmail":
        setFormState({ ...formState, formStateEmail: e.target.value });
        break;
      case "formStateEmailContent":
        setFormState({ ...formState, formStateEmailContent: e.target.value });
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit!", formState);
    props.handleOpenModal();
    db.ref("emails").push().set({
      name: formState.formStateName,
      email: formState.formStateEmail,
      text: formState.formStateEmailContent,
    });
    setFormState({
      formStateName: "",
      formStateEmail: "",
      formStateEmailContent: "",
    });
  };
  return (
    <div className="contactForm">
      <Paper>
        <Container>
          <form
            className={classes.root}
            autoComplete="on"
            onSubmit={(e) => handleSubmit(e)}
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="formStateName" required>
                Name
              </InputLabel>
              <Input
                required
                id="formStateName"
                value={formState.formStateName}
                onChange={(e) => handleChange("formStateName", e)}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel htmlFor="formStateEmail" required>
                E-mail
              </InputLabel>
              <Input
                required
                id="formStateEmail"
                value={formState.formStateEmail}
                onChange={(e) => handleChange("formStateEmail", e)}
              />
              <FormHelperText id="formStateEmail-helper-text">
                I'll never share your email.
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth variant="outlined" size="medium">
              <TextField
                required
                id="formStateEmailContent"
                label="Message"
                multiline
                rows="6"
                value={formState.formStateEmailContent}
                variant="outlined"
                onChange={(e) => handleChange("formStateEmailContent", e)}
              />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<Icon>send</Icon>}
              fullWidth
              style={{ boxShadow: "none" }}
            >
              Submit
            </Button>
          </form>
        </Container>
      </Paper>
    </div>
  );
}
