import React, { useRef, useEffect, useState } from "react";
import { Container, Typography, Box, Grid } from "@material-ui/core";
import HelmetTemplate from "./../HelmetTemplate";
import { motion } from "framer-motion";
import scrollMagic from "scrollmagic";
import ContactForm from "../ContactForm/ContactForm";
import lottie from "lottie-web";
import dataHi from "./../../data_hi.json";
import dataDot from "./../../data_dot.json";
import dataPerson1 from "./../../data_person1.json";
import dataAboutMe from "./../../aboutme.json";
import dataContact from "./../../data_contact.json";
import dataWireframes from "./../../data_wireframes.json";
import aboutImg1 from "./../../images/about_1.jpg";
// import aboutImg2 from './../../images/about_2.jpg'
import aboutImg3 from "./../../images/about_3.jpg";
import aboutImg4 from "./../../images/about_4.jpg";
import aboutImg5 from "./../../images/about_5.jpg";
import aboutImg6 from "./../../images/about_6.jpg";

// import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js";
export default function Top(props) {
  const $top1 = useRef();
  const $top2 = useRef();
  const $top3 = useRef();
  const $top4 = useRef();
  const $top5 = useRef();
  const $blobs = useRef();
  const $dataHi = useRef();
  const $dataDots = useRef();
  const $dataWireframes = useRef();
  const $aboutMeSVG = useRef();
  const $aboutMeImages = useRef();
  const $bottomSVG = useRef();
  const $person1 = useRef();

  const [sceneState, setSceneState] = useState("init");
  // const sceneVariants = {
  //   scene1:"scene1"
  // }

  useEffect(() => {
    console.log("sceneState", sceneState);
    console.log("$top4", $top4);
    if (sceneState === "init") {
      let isResized = false;
      // window.addEventListener("touchmove", (e) => {
      //   console.log("touchmove.noScroll", e);
      //   e.preventDefault();
      // });
      window.addEventListener("resize", () => {
        if (!isResized) {
          isResized = true;
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          // window.location.reload();
        }
      });
      let dataAboutMeSVG;
      dataAboutMeSVG = lottie.loadAnimation({
        container: $aboutMeSVG.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: dataAboutMe,
      });
      const dataHiAnim = lottie.loadAnimation({
        container: $dataHi.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: dataHi,
      });
      const person1Anim = lottie.loadAnimation({
        container: $person1.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: dataPerson1,
      });
      const dataDotAnim1 = lottie.loadAnimation({
        container: $dataDots.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: dataDot,
      });
      const dataDotAnim2 = lottie.loadAnimation({
        container: $dataDots.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: dataDot,
      });
      const dataDotAnim3 = lottie.loadAnimation({
        container: $dataDots.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: dataDot,
      });
      const dataDotAnim4 = lottie.loadAnimation({
        container: $dataDots.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: dataDot,
      });
      const dataDotAnim5 = lottie.loadAnimation({
        container: $dataDots.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: dataDot,
      });
      const dataDotAnims = [
        dataDotAnim1,
        dataDotAnim2,
        dataDotAnim3,
        dataDotAnim4,
        dataDotAnim5,
      ];
      dataDotAnims.forEach((item, i) => {
        const delay = 2000;
        const gutter = 200;
        setTimeout(() => {
          item.play();
        }, delay + gutter * i);
      });
      const dataWireframesAnim = lottie.loadAnimation({
        container: $dataWireframes.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: dataWireframes,
      });
      const dataContactAnim = lottie.loadAnimation({
        container: $bottomSVG.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: dataContact,
      });
      setSceneState("scene1");
      const controller = new scrollMagic.Controller();
      const scene1 = new scrollMagic.Scene({
        triggerElement: "#top__1",
      })
        .setClassToggle("#top__1", "active")
        .duration($top1.current.clientHeight)
        .on("enter", () => {
          document
            .querySelector("#dots svg:nth-of-type(1)")
            .classList.add("active");
          setSceneState("scene1");
          dataHiAnim.setSpeed(0.5);
          setTimeout(() => {
            dataHiAnim.play();
          }, 800);
        })
        .on("leave", () => {
          // setSceneState("scene2");
          document
            .querySelector("#dots svg:nth-of-type(1)")
            .classList.remove("active");
        })
        .addTo(controller);

      const scene2 = new scrollMagic.Scene({
        triggerElement: "#top__2",
      })
        .setClassToggle("#top__2", "active")
        .duration($top2.current.clientHeight)
        .on("enter", () => {
          setSceneState("scene2");
          document
            .querySelector("#dots svg:nth-of-type(2)")
            .classList.add("active");

          dataAboutMeSVG.setDirection(1);
          dataAboutMeSVG.setSpeed(0.1);
          dataAboutMeSVG.play();
          $blobs.current.classList.add("blob__scene2");
          $aboutMeImages.current.classList.add("active");
          $aboutMeSVG.current.classList.add("blob__scene2");
          // console.log("dataAboutMeSVG", dataAboutMeSVG);
        })
        .on("leave", () => {
          // setSceneState("scene3");
          document
            .querySelector("#dots svg:nth-of-type(2)")
            .classList.remove("active");
          $blobs.current.classList.remove("blob__scene2");
          $aboutMeImages.current.classList.remove("active");
          $aboutMeSVG.current.classList.remove("blob__scene2");
          dataAboutMeSVG.setDirection(-1);
          dataAboutMeSVG.setSpeed(2.4);
          dataAboutMeSVG.play();
          // if (dataAboutMeSVG) {
          //   dataAboutMeSVG.addEventListener("complete", () => {
          //     console.log("dataAboutMeSVG", dataAboutMeSVG);
          //     dataAboutMeSVG.destroy();
          //   });
          // }

          // dataAboutMeSVG.loadAnimation();
        })
        .addTo(controller);

      const scene3 = new scrollMagic.Scene({
        triggerElement: "#top__3",
      })
        .setClassToggle("#top__3", "active")
        .duration($top3.current.clientHeight)
        .on("enter", () => {
          setSceneState("scene3");
          document
            .querySelector("#dots svg:nth-of-type(3)")
            .classList.add("active");
          $blobs.current.classList.add("blur");
          dataWireframesAnim.setSpeed(0.7);
          dataWireframesAnim.playSegments([0, 519], true);
          dataWireframesAnim.addEventListener("complete", () => {
            dataWireframesAnim.playSegments([180, 519], true);
          });
        })
        .on("leave", () => {
          // setSceneState("scene4");
          document
            .querySelector("#dots svg:nth-of-type(3)")
            .classList.remove("active");
          $blobs.current.classList.remove("blur");
          dataAboutMeSVG.setDirection(-1);
          dataAboutMeSVG.setSpeed(100);
          dataAboutMeSVG.goToAndStop(0, true);
        })
        .addTo(controller);

      const scene4 = new scrollMagic.Scene({
        triggerElement: "#top__4",
      })
        .setClassToggle("#top__4", "active")
        .duration($top4.current.clientHeight)
        .on("enter", () => {
          setSceneState("scene4");
          document
            .querySelector("#dots svg:nth-of-type(4)")
            .classList.add("active");
          $blobs.current.classList.add("blur2");
        })
        .on("leave", () => {
          // setSceneState("scene5");
          document
            .querySelector("#dots svg:nth-of-type(4)")
            .classList.remove("active");
          $blobs.current.classList.remove("blur2");
        })
        .addTo(controller);

      const scene5 = new scrollMagic.Scene({
        triggerElement: "#top__5",
      })
        .setClassToggle("#top__5", "active")
        .duration($top5.current.clientHeight)
        .on("enter", () => {
          setSceneState("scene5");
          document
            .querySelector("#dots svg:nth-of-type(5)")
            .classList.add("active");
          $blobs.current.classList.add("blur2");
        })
        .on("leave", () => {
          // setSceneState("scene5");
          document
            .querySelector("#dots svg:nth-of-type(5)")
            .classList.remove("active");
          $blobs.current.classList.remove("blur2");
        })
        // .on("leave", () => {
        //   setSceneState("scene1");
        // })
        .addTo(controller);
    }
  }, [sceneState, setSceneState]);
  let blobScale = 1;
  let blobX = 0;
  let windowWidth = document.body.clientWidth;
  if (windowWidth < 450) {
    blobScale = 0.9;
    blobX = "-5vw";
  } else if (windowWidth < 760) {
    blobScale = 0.6;
    blobX = "7vw";
  } else if (windowWidth < 900) {
    blobScale = 0.6;
    blobX = "7vw";
  } else if (windowWidth < 1100) {
    blobScale = 0.9;
    blobX = "3vw";
  }

  const blobsVariant = {
    initial: {
      scale: 20,
      rotate: 3600,
      x: blobX,
      y: 0,
      position: "absolute",
      transition: {
        duration: 0.4,
        type: "spring",
      },
    },
    scene1: {
      scale: blobScale,
      rotate: 0,
      x: blobX,
      y: 0,
      position: "absolute",
      transition: {
        duration: 0.7,
        type: "spring",
      },
    },
    scene2: {
      scale: 0.2,
      rotate: 0,
      x: "-40vw",
      y: "100vh",
      position: "absolute",
      transition: {
        duration: 0.4,
        type: "spring",
      },
    },
    scene3: {
      scale: 1.7,
      rotate: 0,
      x: "-15vw",
      y: "200vh",
      position: "absolute",
      transition: {
        duration: 0.4,
        // ease: "easeIn",
        type: "spring",
      },
    },
    scene4: {
      scale: 0.8,
      rotate: 20,
      x: "-15vw",
      y: "0",
      position: "fixed",
      transition: {
        duration: 1.4,
        // ease: "easeIn",
        type: "spring",
      },
    },
    scene5: {
      scale: 0.0,
      rotate: 720,
      x: "-15vw",
      y: "0",
      position: "fixed",
      transition: {
        duration: 0.4,
        type: "tween",
      },
    },
    touch: {
      scale: 1.02,
      rotate: 10,
      transition: {
        duration: 0.1,
        type: "spring",
      },
    },
  };

  const spanVariantDelay = 5.5;
  const spanVariantDulation = 0.2;

  const spanVariant = {
    hidden: {
      x: 200,
      opacity: 0,
    },
    visible1: {
      x: 0,
      opacity: 1,
      transition: {
        delay: spanVariantDelay + 0,
        duration: spanVariantDulation,
        type: "spring",
      },
    },
    visible2: {
      x: 0,
      opacity: 1,
      transition: {
        delay: spanVariantDelay + 0.2,
        duration: spanVariantDulation,
        type: "spring",
      },
    },
    visible3: {
      x: 0,
      opacity: 1,
      transition: {
        delay: spanVariantDelay + 0.4,
        duration: spanVariantDulation,
        type: "spring",
      },
    },
    visible4: {
      x: 0,
      opacity: 1,
      transition: {
        delay: spanVariantDelay + 0.5,
        duration: spanVariantDulation,
        type: "spring",
      },
    },
  };
  const contactSpanVariantDelay = 0;
  const contactSpanVariantDulation = 2;
  const contactVariants = {
    initial: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene1: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene2: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene3: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene4: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene5: {
      scale: 1,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        stiffness: 100,
        damping: 5,
        mass: 1,
        type: "spring",
      },
    },
  };
  const contactVariants2 = {
    initial: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene1: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene2: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene3: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene4: {
      scale: 0,
      transition: {
        delay: contactSpanVariantDelay + 0,
        duration: contactSpanVariantDulation,
        type: "spring",
      },
    },
    scene5: {
      scale: 1,
      transition: {
        delay: contactSpanVariantDelay + 0.2,
        duration: contactSpanVariantDulation,
        stiffness: 100,
        damping: 10,
        mass: 1,
        type: "spring",
      },
    },
  };

  return (
    <div className="top">
      <HelmetTemplate
        title="Top Page"
        description="Seiya Murayama is a UI / UX designer and Frontend developper based in Vancouver Canada."
      />
      <motion.div
        id="blobs"
        variants={blobsVariant}
        initial="initial"
        animate={sceneState}
        // whileHover="touch"
        ref={$blobs}
      >
        <svg
          id="blob7"
          width="647"
          height="641"
          viewBox="0 0 647 641"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#fff"
          />
        </svg>
        <svg
          id="blob8"
          width="647"
          height="641"
          viewBox="0 0 647 641"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#fff"
          />
        </svg>
        <svg
          id="blob1"
          width="617"
          height="611"
          viewBox="0 0 617 611"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#2F80ED"
          />
        </svg>
        <svg
          id="blob2"
          width="617"
          height="611"
          viewBox="0 0 617 611"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#2F80ED"
          />
        </svg>
        <svg
          id="blob3"
          width="617"
          height="611"
          viewBox="0 0 617 611"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#2F80ED"
          />
        </svg>
        <svg
          id="blob4"
          width="617"
          height="611"
          viewBox="0 0 617 611"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#2F80ED"
          />
        </svg>
        <svg
          id="blob5"
          width="617"
          height="611"
          viewBox="0 0 617 611"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#2F80ED"
          />
        </svg>
        <svg
          id="blob6"
          width="617"
          height="611"
          viewBox="0 0 617 611"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#2F80ED"
          />
        </svg>
        <svg
          id="blob9"
          width="517"
          height="511"
          viewBox="0 0 517 511"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#2F80ED"
          />
        </svg>
        <svg
          id="blob10"
          width="517"
          height="511"
          viewBox="0 0 517 511"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M616.5 311.5C616.5 479.118 459.118 610.5 291.5 610.5C123.882 610.5 0.5 494.118 0.5 326.5C0.5 158.882 79.5 -10.5 313.5 0.999972C547.5 12.5 616.5 143.882 616.5 311.5Z"
            fill="#2F80ED"
          />
        </svg>
      </motion.div>
      <div id="aboutMeSVG" ref={$aboutMeSVG}></div>
      <div id="aboutMeImages" ref={$aboutMeImages}>
        <div className="top__2__images">
          <img id="top__2__images_1" src={aboutImg1} alt="" />
          <img id="top__2__images_3" src={aboutImg3} alt="" />
          <img id="top__2__images_4" src={aboutImg4} alt="" />
          <img id="top__2__images_5" src={aboutImg5} alt="" />
          <img id="top__2__images_6" src={aboutImg6} alt="" />
        </div>
      </div>
      <section id="top__1" ref={$top1}>
        <Container maxWidth="lg">
          {/* <Typography
            component="p"
            variant="h3"
            style={{ marginBottom: "1rem" }}
          >
            <motion.span
              variants={spanVariant}
              initial="hidden"
              animate="visible1"
            >
              Hi, I'm
            </motion.span>
          </Typography>
          <Typography
            component="h1"
            variant="h1"
            style={{ marginBottom: "1rem" }}
          >
            <motion.span
              variants={spanVariant}
              initial="hidden"
              animate="visible2"
            >
              Seiya
            </motion.span>
          </Typography> */}
          <div id="dataHi" ref={$dataHi}></div>
          <Typography component="p" variant="h5" style={{ paddingTop: "10px" }}>
            <motion.span
              variants={spanVariant}
              initial="hidden"
              animate="visible3"
            >
              Web Designer
            </motion.span>
          </Typography>
          <Typography component="p" variant="h5">
            <motion.span
              variants={spanVariant}
              initial="hidden"
              animate="visible4"
            >
              UI / UX Design / Frontend
            </motion.span>
          </Typography>
        </Container>
      </section>
      <section id="top__2" ref={$top2} style={{ backgroundColor: "#fff" }}>
        <Container maxWidth="lg" style={{ display: "flex" }}>
          <Box py={8} style={{ maxWidth: "40%" }}>
            <Typography component="h2" variant="h3">
              A little about myself...
            </Typography>
            <Typography component="p" variant="body1">
              I have over 10 years of experience working in Web industry (Japan,
              US, Canada) as a UI//UX designer, markup coder and frontend
              developer. Currently, I'm living in Vancouver, Canada.
            </Typography>
          </Box>
        </Container>
      </section>

      <section id="top__3" ref={$top3} style={{ backgroundColor: "#fff" }}>
        <Container maxWidth="xs">
          <Typography
            component="h2"
            variant="h3"
            style={{ marginBottom: "1rem" }}
          >
            What I do
          </Typography>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={0}
              id="top__3__card__1"
            >
              <Typography component="h3" variant="h4">
                Design
              </Typography>
              <ul>
                <li>UI / UX design for apps and websites.</li>
                <li>CSS / JS / SVG animation.</li>
                <li>Event, campaign proposal.</li>
                <li>Web banner design.</li>
                {/* <li>Branding logo design.</li> */}
              </ul>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={0}
              id="top__3__card__2"
            >
              <Typography component="h3" variant="h4">
                Coding
              </Typography>
              <ul>
                <li>Optimizing website for maximum user experience.</li>
                <li>Implementing functionality.</li>
                <li>Building server-side architecture with node.js.</li>
              </ul>
            </Grid>
            <div id="dataWireframesSVG" ref={$dataWireframes}></div>
          </Grid>
        </Container>
      </section>
      <section id="top__4" ref={$top4} style={{ backgroundColor: "#fff" }}>
        <Container maxWidth="lg">
          <Box py={8} maxWidth="100%">
            <Typography component="h2" variant="h3">
              <span>Things I am familiar with</span>
              <div id="person1" ref={$person1}></div>
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              // alignItems="center"
              spacing={4}
            >
              <Grid container item xs={12} sm={4} spacing={0}>
                <Typography component="h3" variant="h4">
                  Design / Coding
                </Typography>
                <ul>
                  <li>
                    <span>Photoshop</span>
                    <span className="width50"></span>
                  </li>
                  <li>
                    <span>Figma</span>
                    <span className="width40"></span>
                  </li>
                  <li>
                    <span>Illustrator</span>
                    <span className="width40"></span>
                  </li>
                  <li>
                    <span>After Effects</span>
                    <span className="width30"></span>
                  </li>
                  <li>
                    <span>Procreate(iPad)</span>
                    <span className="width30"></span>
                  </li>
                  <li>
                    <span>Concepts(iPad)</span>
                    <span className="width30"></span>
                  </li>
                  <li>
                    <span>HTML</span>
                    <span className="width50"></span>
                  </li>
                  <li>
                    <span>CSS / SASS</span>
                    <span className="width50"></span>
                  </li>
                </ul>
              </Grid>
              <Grid container item xs={12} sm={4} spacing={0}>
                <Typography component="h3" variant="h4">
                  Programming
                </Typography>
                <ul>
                  <li>
                    <span>Javascript</span>
                    <span className="width50"></span>
                  </li>
                  <li>
                    <span>Typescript</span>
                    <span className="width30"></span>
                  </li>
                  <li>
                    <span>PHP</span>
                    <span className="width30"></span>
                  </li>
                  <li>
                    <span>C#</span>
                    <span className="width20"></span>
                  </li>
                  <li>
                    <span>Python</span>
                    <span className="width20"></span>
                  </li>
                  <li>
                    <span>Elm</span>
                    <span className="width10"></span>
                  </li>
                </ul>
              </Grid>
              <Grid container item xs={12} sm={4} spacing={0}>
                <Typography component="h3" variant="h4">
                  Javascript
                </Typography>
                <ul>
                  <li>
                    <span>React</span>
                    <span className="width50"></span>
                  </li>
                  <li>
                    <span>Material-ui</span>
                    <span className="width40"></span>
                  </li>
                  <li>
                    <span>GSAP</span>
                    <span className="width40"></span>
                  </li>
                  <li>
                    <span>Framer-Motion</span>
                    <span className="width40"></span>
                  </li>
                  <li>
                    <span>jQuery</span>
                    <span className="width40"></span>
                  </li>
                  <li>
                    <span>Gatsby</span>
                    <span className="width30"></span>
                  </li>
                  <li>
                    <span>Node.js</span>
                    <span className="width30"></span>
                  </li>
                  <li>
                    <span>D3</span>
                    <span className="width30"></span>
                  </li>
                  <li>
                    <span>Electron</span>
                    <span className="width20"></span>
                  </li>
                </ul>
              </Grid>

              <Grid container item xs={6} sm={2} spacing={0}>
                <Typography component="h3" variant="h5">
                  Environment
                </Typography>
                <ul>
                  <li>VScode</li>
                  <li>Sublime</li>
                  <li>Webpack</li>
                  <li>Firebase</li>
                  <li>Heroku</li>
                  <li title="since Windows95">Windows</li>
                  <li>Mac</li>
                </ul>
              </Grid>
              <Grid container item xs={6} sm={2} spacing={0}>
                <Typography component="h3" variant="h5">
                  For Fun
                </Typography>
                <ul>
                  <li>Unity</li>
                  <li>Fusion360</li>
                  <li>3D Printing</li>
                  <li>T-shirts design</li>
                  <li>p5.js</li>
                </ul>
              </Grid>
              <Grid container item xs={6} sm={2} spacing={0}>
                <Typography component="h3" variant="h5">
                  Sports
                </Typography>
                <ul>
                  <li>Badminton</li>
                  <li>10km run</li>
                  <li>Hiking</li>
                </ul>
              </Grid>
              <Grid container item xs={6} sm={2} spacing={0}>
                <Typography component="h3" variant="h5">
                  Table / board / card Games
                </Typography>
                <ul>
                  <li>Poker</li>
                  <li>Dominion</li>
                  <li>Avalon</li>
                  <li>One night werewolf</li>
                  <li>Catan</li>
                </ul>
              </Grid>
              <Grid container item xs={6} sm={2} spacing={0}>
                <Typography component="h3" variant="h5">
                  Game Console
                </Typography>
                <ul>
                  <li>Switch</li>
                  <li>PS3,2,1,PSP</li>
                  <li>Xbox360</li>
                  <li>Wii</li>
                  <li>N64</li>
                  <li>Sega Saturn</li>
                  <li>SNES</li>
                  <li>GB, GB Advance, GBSP</li>
                  <li>NDS</li>
                  <li>WonderSwan</li>
                </ul>
              </Grid>
              <Grid container item xs={6} sm={2} spacing={0}>
                <Typography component="h3" variant="h5">
                  Language
                </Typography>
                <ul>
                  <li>Japanese (Tokyo)</li>
                  <li>Japanese (Saga)</li>
                  <li>English (Canada)</li>
                </ul>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </section>
      <section id="top__5" ref={$top5} style={{ backgroundColor: "#fff" }}>
        <Container maxWidth="lg">
          <Box
            py={8}
            display="flex"
            minHeight="100vh"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Box>
              <Typography variant="h4" component="h2" gutterBottom>
                <motion.span
                  variants={contactVariants}
                  initial="initial"
                  animate={sceneState}
                >
                  Would you like to work with me?
                </motion.span>
              </Typography>
              <motion.div
                variants={contactVariants2}
                initial="initial"
                animate={sceneState}
              >
                <ContactForm
                  handleOpenModal={props.handleOpenModal}
                  handleCloseModal={props.handleCloseModal}
                />
              </motion.div>
            </Box>
          </Box>
        </Container>
        <div id="bottom__deco">
          <div className="roundedBottom"></div>
          <div id="bottomSVG" ref={$bottomSVG}></div>
          <p>&copy;seiyamurayama.com</p>
        </div>
      </section>
      <div id="dotNav">
        <div id="dots" ref={$dataDots}></div>
      </div>
    </div>
  );
}
