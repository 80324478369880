import firebase from "firebase/app";
import "firebase/database";
import "firebase/firebase-storage";
import "firebase/firebase-functions";
// const functions = require("firebase-functions");
const config = {
  apiKey: "AIzaSyC6h0qAaD14vjhtxRuUwFoay568_eRzD4U",
  authDomain: "seiya-3.firebaseapp.com",
  databaseURL: "https://seiya-3.firebaseio.com",
  projectId: "seiya-3",
  storageBucket: "seiya-3.appspot.com",
  messagingSenderId: "427665197975",
  appId: "1:427665197975:web:689fa6eceb8214626f12d2",
  measurementId: "G-213TN6LE8Q",
};

firebase.initializeApp(config);
export const db = firebase.database();
export const functions = firebase.functions();
export const storage = firebase.storage();
