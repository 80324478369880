import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { blue, pink } from "@material-ui/core/colors/";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: ['"Comfortaa"', "cursive"].join(","),
    h3: {
      display: "block",
      marginBottom: "1rem",
    },
    h4: {
      display: "block",
      marginBottom: "1rem",
    },
    h5: {
      display: "block",
      marginBottom: "1rem",
    },
  },
  overrides: {
    MuiGrid: {
      item: {
        display: "block",
      },
      container: {
        alignItems: "start",
      },
    },
  },
});

export default theme;
