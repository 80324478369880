import React, { useState, useRef, useEffect } from "react";
import { Container, Typography, Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import lottie from "lottie-web";
import dataPerson1 from "./../../data_person1.json";

export default function Notfound() {
  const [sceneState, setSceneState] = useState("init");
  const $person1 = useRef();

  useEffect(() => {
    if (sceneState === "init") {
      console.log($person1);
      const person1Anim = lottie.loadAnimation({
        container: $person1.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: dataPerson1,
      });
    }
  }, [sceneState, setSceneState]);

  return (
    <div className="notfound">
      <Container maxWidth="lg">
        <Box py={8} style={{ textAlign: "center" }}>
          <Typography component="h1" variant="h1">
            404
          </Typography>
          <Typography component="p" variant="h4">
            Geeeez!!
          </Typography>
          <Typography component="p" variant="body1">
            Page not found.
          </Typography>
          <Typography component="p" variant="body1">
            Please, go back to the top page.
          </Typography>
          <div id="person1" ref={$person1}></div>

          <div style={{ marginTop: "24px" }}>
            <Link to="/">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ boxShadow: "none" }}
              >
                GO TO TOP
              </Button>
            </Link>
          </div>
        </Box>
      </Container>
    </div>
  );
}
